.App {
  display: flex;
  flex-direction: column;
  background-image: url("../public/images/background.webp");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

h2 {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  width: auto;
  color: white;
  border-radius: 15px;
  padding: 5rem;
  margin: 0;
  font-family: "PigPenCodeFont";
  letter-spacing: 10px;
  text-align: center;

}

@media screen and (max-width: 426px) {
  .App {
    background-image: url("../public/images/background-mobile.webp");
  }

  h2 {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 587px) {
  h2 {
    border-radius: 0;
  }
}

@font-face {
  font-family: "PigPenCodeFont";
  src: url("../public/fonts/PigPenCodeFont.woff") format("woff");
}

h1 {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}